import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import { FaStar } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

export default function Reviews() {
  return (
    <>
      <NavBar
        title="Reviews | Point Blank Moving"
        description="Point Blank Moving company reviews page. Kansas City's premier moving company! Learn what others have to say about us."
      />
      <IconContext.Provider
        value={{ color: "#ffd700", size: "15px", alignItems: "center" }}
      >
        <div className="reviews-landing-container">
          <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
            Reviews
          </h1>
          <div className="landing-btns">
            <Button
              className="btns"
              buttonStyle="btn--primary"
              buttonSize="btn--large"
              path="/Quote"
            >
              Check Availability
            </Button>
            <Button
              className="btns"
              buttonStyle="btn--outline"
              buttonSize="btn--large"
              path="/Contact"
            >
              GET IN CONTACT
            </Button>
          </div>
        </div>
        <div className="reviews-align">
          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Azmi J</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                These guys have been great. If I could give more stars I would.
                Even on a day with bad weather they were punctual and they were
                very considerate with being clean and taking their shoes off at
                our new house. One of the furniture parts fell and they came by
                my house to drop it off. These guys were professional,
                courteous, punctual, and the value for their service is great.
                Their responses are also fast and they are always ready to help.
                I have recommended their outstanding service to my colleagues.
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Jennifer Vochatzer</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                The team of guys that moved me were AMAZING! Cooper, Drew, and
                Charlie were efficient and caring about all of my furniture.
                They were timely, solid communication skills, and wanted to make
                my day as easy as possible. Did I mention entertaining? These
                guys were so funny, they actually made moving fun. I would
                definitely hire Point Blank Moving again... but hopefully I'm
                not moving for a long time (sorry guys!).{" "}
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Gloria Rodriguez</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                Very good experience with Point Blank Moving. Owner, Mike Garcia
                responded quickly. Movers were friendly, fast, professional and
                careful with my things. They placed items and furniture where I
                needed them. This is a stressful time for me, but these movers
                made things less stressful. Pricing was good. Highly recommend
                this company.{" "}
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Matthew Byrd</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                This experience was top-notch from the very beginning! I barely
                had time to create the job in Zaarly before Mike called me to
                discuss my move. He was very forthright and honest in his
                assessment of what the move would look like. He sent me the
                quote and it was significantly less than what I was budgeting
                and then our closing date changed and he was able to change our
                move to the following day. Once the date of our move arrived the
                guys showed up and they were FANTASTIC! They were having fun and
                you can tell they loved their job. Cooper, Tanner, and Charlie
                were the epitome of hard workers. They treated our belongings
                like their own. Unlike some of their competitors in the industry
                they didn’t utilize hand trucks for boxes, they carried them.
                Hopefully I never have to move again but if I do I will use
                Point Blank Movers and I will refer them to anyone who ever asks
                about movers. Thanks for everything you did to make our move a
                pleasure.{" "}
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Jessica Clark</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                Looking to move? Look no further than this crew! We used them to
                move our things from KS to AL in 2019. 2020 came around we had a
                tiny addition to our family, making moving by ourselves
                practically impossible. We called up PBM and asked if they would
                be willing to re-move our stuff from AL all the way back to KS!
                (That’s how happy we were with our first move - both me and my
                husband REALLY wanted Hayden and Cooper again for our move!).
                Here we are, back in KS because of the most amazing duo around!
                All of our items were professionally wrapped up in pads or
                plastic, carefully placed in the truck/home, and rearranged to
                our content. Outstanding communication and care for our items as
                well as our home (door frames covered in padding, etc.) We hope
                to not move again for a LONG time, but we love this company and
                as long as they’re open we will always hire them for our moving
                needs! Thanks Hayden and Cooper for seeing us again and taking
                up the job.
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Nicole Weston</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                We hired Point Blank Moving to move wedding and special occasion
                gowns and 3 really heavy jewelry cases from our retail location
                to our storage unit. 1st the communication was beyond my
                expectations! They were fast communicators and able to
                accommodate different communication methods as well. They also
                took the time out to come to our location to evaluate our needs.
                This morning they came and arrived 15 minutes early! We loved
                that they were very punctual. We had a team of four guy's. THEY
                WERE BEYOND AMAZING!! They worked very fast as compared to other
                companies we have used in the past. They were super organized
                and efficient. I could go on and on about how amazing their team
                was.... But, JUST HIRE THEM!!!! You will not regret it. P.S. I
                called 20 Companies and their price for the service we received
                was amazing. Thanks Mike and Point Blank Team! From The Gown
                Gallery
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">David Hoffman</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                Mike and his crew were amazing! They took great care of our
                things and worked really hard to deliver an exceptional moving
                experience. They were professional, friendly, and very helpful.
                I couldn't be more pleased and would recommend them to ANYONE!
                Thank you, Point Blank, for being on time, careful, efficient,
                and under budget!
              </p>{" "}
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Tim Bonnell Jr.</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                Point Blank moving did a fantastic job across all areas of our
                move. From communication and responsiveness to the care of our
                household items and home while moving them in and out. I've
                really never had a mover do any of these things well. They had a
                clean, professional, and friendly team of movers. I would
                recommend Point Blank Moving to all of my family and friends.
                This really was exceptional service. Thank you.{" "}
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Jonathan Sandhu</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                I had to arrange a move on short notice, while out of state.
                Point Blank Moving was quick to respond to the quote and walk
                through the booking options. During the move, Mike and his team
                adjusted to a few surprises we had, even bringing in an extra
                truck to minimize move time (i.e., keeping costs down.) I can't
                thank Point Blank Moving enough for making a stressful situation
                easier.{" "}
              </p>
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Teresa Hogan</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                These gentlemen are AMAZING! My sister and her husband used
                Point Blank movers a couple of weeks ago. I was so impressed.
                These guys were so professional and so very careful with each
                piece of furniture. They were also very speedy...especially
                given all the furniture and pieces that needed to be carefully
                moved. They hustled up and down stairs and around tight corners
                with no difficulties. Even better, they were so pleasant and
                FUN! I have moved several times and never encountered a crew
                like this! WOW! A+ rating and easy 5 stars! Nice work guys!
              </p>{" "}
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Jeff B</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                I hired Point Blank moving to move our 4 bedroom home and they
                did an awesome job! Extremely efficient and hard working. No
                damage to anything and communication was great! Would HIGHLY
                recommend this company!
              </p>{" "}
            </div>
          </div>

          <div className="reviews-container">
            <div className="reviews-box">
              <h3 className="reviews-user">Maria Goddard</h3>
              <h4 className="reviews-rating">
                5.0 <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
              </h4>
              <p className="reviews-content">
                I have moved many times in my life and the team at Point Blank
                have them all beat for speed and efficiency. But don’t think
                that they compromise quality for speed; of my items were treated
                with great care. They are punctual, communicate well and are a
                fun respectful team. I have been telling everyone I know about
                them and will definitely be a repeat customer. Save yourself the
                headache of moving and hire these guys ASAP!
              </p>{" "}
            </div>
          </div>
        </div>
      </IconContext.Provider>

      <Footer />
    </>
  );
}
